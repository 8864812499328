import React from 'react';
import { Link } from 'gatsby';

export default function PostNavigator({ pageContext }) {
	const { previous, next } = pageContext
	return (
		<ul className="list-inline m-0 p-0 navigator">
			<li className="list-inline-item">
				{previous && (
					<Link to={previous.fields.slug} rel="prev">
						← {previous.frontmatter.title}
					</Link>
				)}
			</li>
			<li className="list-inline-item">
				{next && (
					<Link to={next.fields.slug} rel="next">
						{next.frontmatter.title} →
					</Link>
				)}
			</li>
		</ul>
	)
}
