import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostNavigator from "../components/PostNavigator"
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

export default function BlogPost({ data, pageContext, location }) {
	
	const post = data.markdownRemark;
	// console.log('----->', data, location);
	// console.log('---x-->', pageContext);
	// console.log(post);

	let disqusConfig = {
		url: location.href,
		identifier: data.slug,
		title: post.frontmatter.title,
	};

	return (
		<Layout location={location} title={post.frontmatter.title} pic={post.frontmatter.pic}>
			<div className="blog-post">
				<h2 className="blog-title">{post.frontmatter.title}</h2>
				{post.frontmatter.pic ? <div className="blog-title-img"><img src={post.frontmatter.pic}></img></div> : null }
				<div dangerouslySetInnerHTML={{ __html: post.html }} />
			</div>
			<PostNavigator pageContext={pageContext} />

			<CommentCount config={disqusConfig} placeholder={''} />
			<Disqus config={disqusConfig} />
		</Layout>
	)
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				pic
				tags
			}
		}
	}
`